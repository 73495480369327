<template>
  <lf-modal :small="true" :title="title" titleBorder :close="close">
    <template #content>
      <div class="p-6">
        <slot name="prepend" />
        <p class="text-headline break-words">{{ description }}</p>
        <div v-if="warnings?.length">
          <p
            v-for="(warning, i) in warnings"
            :key="i"
            class="text-red-500 text-xs mt-2"
          >
            {{ warning }}
          </p>
        </div>
      </div>
      <div class="border-t border-gray-200 p-6 flex justify-end">
        <outline-button class="mr-3" @click="cancel">
          {{ cancelButton }}
        </outline-button>
        <color-button
          data-cy="confirm-button"
          :type="confirmButtonType"
          @click="onConfirm"
        >
          {{ confirmButton }}
        </color-button>
      </div>
    </template>
  </lf-modal>
</template>
<script setup lang="ts">
import LfModal from "@/components/ui/Modal.vue";
import type { PropType } from "vue";
import OutlineButton from "@/components/ui/buttons/OutlineButton.vue";
import ColorButton from "@/components/ui/buttons/ColorButton.vue";

const props = defineProps({
  description: {
    type: String,
    default: "Are you sure that you want to continue?"
  },
  confirmButtonType: {
    type: String as PropType<"success" | "error" | "warning" | "submit">,
    default: "success"
  },
  confirmButton: {
    type: String,
    default: "Yes"
  },
  cancelButton: {
    type: String,
    default: "Cancel"
  },
  warnings: {
    type: Array,
    required: false
  },
  close: {
    type: Function as PropType<() => void>,
    required: true
  },
  title: {
    type: String,
    default: "Confirmation"
  },
  closeOnConfirm: {
    type: Boolean,
    default: true
  }
});
const emit = defineEmits(["confirm", "cancel"]);

const onConfirm = () => {
  emit("confirm");
  if (props.closeOnConfirm) {
    props.close();
  }
};

const cancel = () => {
  props.close();
  emit("cancel");
};
</script>
